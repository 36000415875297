<template>
  <div class="property-form">
    <FormFieldsComponent :formFields="baseFields" :editable="canUpdate" ref="baseFields" @onCompletionChange="onBaseFieldsCompletionChange">
      <template #addresses="{ field }">
        <b-field :label="field.label">
          <div
            class="address-wrap"
            v-for="(address, index) in field.value"
            :key="index"
          >
            <AddressForm
              :address="field.value[index]"
              :index="index"
              :hasDeleteButton="true"
              :hasGoogleAutoComplete="true"
              :editable="canUpdate"
              @addressFormCompleted="(status) => {addressFormCompleted.splice(index, 1, status)}"
              @onAddressDeletion="onAddressDeletion(index)"
            ></AddressForm>
          </div>
        </b-field>
        <a v-if="canUpdate" @click="newAddress" class="add-address has-text-primary has-text-weight-semibold ml-3">New Address +</a>
      </template>
      <template #issuanceMetaTemplateIds="{ formData }">
        <b-field label="Jurisdictions" v-show="formData.country === 'Canada'">
          <multiselect
            v-model="customFields.issuanceMetaTemplateIds"
            :options="provinces"
            label="name"
            trackBy="code"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder=" "
            multiple
            :editable="canUpdate"
          ></multiselect>
        </b-field>
      </template>
      <template #customPropTypeAndOfferingDate="{ field }">
        <div class="is-flex is-justify-content-space-between prop-type-and-offering-date">
          <b-field label="Process Type">
            <b-select placeholder="Select type" v-model="customFields.purchaseProcessType" :disabled="!canUpdate">
              <option
                v-for="option in field.options"
                :key="option.key"
                :value="option.key"
              >{{option.name}}</option>
            </b-select>
          </b-field>
          <div class="is-relative" v-show="customFields.purchaseProcessType === 'offering_memorandum'">
            <b-field label="Offering Date">
              <b-datepicker
                editable
                v-model="customFields.offeringDate"
                locale="en-CA"
                placeholder="YYYY-MM-DD"
                icon="calendar-week"
                :disabled="!canUpdate"
              ></b-datepicker>
            </b-field>
            <span class="icon reset is-right is-clickable" @click="customFields.offeringDate = null">
              <i class="fa fa-times-circle"></i>
            </span>
          </div>
        </div>
      </template>
    </FormFieldsComponent>
    <slot v-if="canUpdate" name="bottom">
      <b-button @click="onSave" type="is-blue" class="mt-3" :disabled="!formCompleted">Save</b-button>
    </slot>
  </div>
</template>
<script>
import FormFieldsComponent from '@components/form/form-fields'
import AddressForm from '@components/form/address-form'
import Multiselect from 'vue-multiselect'
import { baseFields } from '@utils/data/addy_plus_property_fields'
import CanadaProvinces from '@utils/data/canada_provinces'

export default {
  components: {
    FormFieldsComponent,
    Multiselect,
    AddressForm,
  },
  data() {
    return {
      baseFields,
      customFields: {
        issuanceMetaTemplateIds: [],
        purchaseProcessType: null,
        offeringDate: null,
      },
      isValid: false,
      provinces: Object.freeze(CanadaProvinces),
      isOpen: null,
      addressFormCompleted: [],
    }
  },
  props: {
    fields: Object,
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    addressField() {
      return this.baseFields.find((field) => field.key === 'addresses')
    },
    formCompleted() {
      return this.isValid && this.addressFormCompleted.every((field) => field)
    },
  },
  watch: {
    fields() {
      this.autoPopulateFields()
    },
  },
  methods: {
    onSave() {
      this.$emit('onSave', this.handleFormData())
    },
    handleFormData() {
      const copy = JSON.parse(JSON.stringify(this.$refs.baseFields.formData))
      delete copy.customPropTypeAndOfferingDate
      // delete copy.issuanceMetaTemplateIds
      const { purchaseProcessType, offeringDate } = this.customFields
      // return { ...copy, purchaseProcessType, offeringDate, issuanceMeta: { template_ids: this.convertJurisdictionsToTemplateIds() } }
      return { ...copy, purchaseProcessType, offeringDate }
    },
    // convertJurisdictionsToTemplateIds() {
    //   const jurisdictions = this.customFields.issuanceMetaTemplateIds
    //   if (!jurisdictions.length) return null
    //   const obj = {}
    //   jurisdictions.forEach((jurisdiction) => {obj[jurisdiction.code] = 'N/A'})
    //   return obj
    // }
    onBaseFieldsCompletionChange(isCompleted) {
      this.isValid = isCompleted
    },
    autoPopulateFields() {
      this.dataPopulation('baseFields')
      this.populateCustomFields()
    },
    dataPopulation(ref) {
      const copy = JSON.parse(JSON.stringify(this[ref]))
      copy.forEach((field) => {field.value = this.fields[field.key]})
      this[ref] = copy
    },
    populateCustomFields() {
      // const { purchaseProcessType, offeringDate, templateIds } = this.fields
      const { purchaseProcessType, offeringDate } = this.fields
      this.customFields.purchaseProcessType = purchaseProcessType
      this.customFields.offeringDate = new Date(offeringDate?.replaceAll('-', '/'))
      // const jurisdictions = Object.keys(templateIds)
      // this.customFields.issuanceMetaTemplateIds = this.provinces.filter((province) => jurisdictions.includes(province.code))
    },
    onAddressFormClose() {
      this.isOpen = null
    },
    newAddress() {
      const addresses = this.addressField.value
      this.$emit('newAddress')
      this.addressFormCompleted.push(false)
      this.isOpen = addresses.length ? addresses.length - 1 : 0
    },
    onAddressDeletion(index) {
      this.$emit('onAddressDeletion', index)
      this.addressFormCompleted.splice(index, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/addy-variables.scss';
.property-form {
  max-width: 800px;
  .section-title {
    font-size: 20px;
  }
  .prop-type-and-offering-date {
    > div {
      width: 49%;
      margin-bottom: 0;
      .field {
        margin-bottom: 0;
      }
      .icon.reset {
        position: absolute;
        right: 8px;
        bottom: 0;
        height: 40px;
        .fa {
          font-size: 16px;
          color: var(--addy-blue-light-1);
        }
      }
    }
  }
  .address-wrap:not(:last-child) {
    margin-bottom: 8px;
  }
  .add-address {
    font-size: 14px;
  }
}
</style>
