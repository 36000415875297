const baseFields = [
  {
    key: 'nickname',
    label: 'Nick Name',
    type: 'text-input',
    required: true,
    value: null,
  },
  {
    key: 'name',
    label: 'Name',
    type: 'text-input',
    required: true,
    value: null,
  },
  {
    key: 'addresses',
    label: 'Addresses',
    type: 'custom',
    required: false,
    value: [],
  },
  // {
  //   key: 'nameSpaceUrl',
  //   label: 'Name Space Url',
  //   type: 'text-input',
  //   required: true,
  //   value: null,
  // },
  // {
  //   key: 'saleStatus',
  //   label: 'Sale Status',
  //   type: 'select',
  //   options: [
  //     {
  //       key: 'hidden',
  //       name: 'Hidden',
  //     },
  //     {
  //       key: 'pre_sale',
  //       name: 'Coming Soon',
  //     },
  //     {
  //       key: 'sale',
  //       name: 'For Sale',
  //     },
  //     {
  //       key: 'post_sale',
  //       name: 'Sold Out',
  //     },
  //   ],
  //   required: true,
  //   value: 'hidden',
  // },
  {
    key: 'country',
    label: 'Country',
    type: 'select',
    options: [
      {
        key: 'Canada',
        name: 'Canada',
      },
      {
        key: 'United States',
        name: 'United States',
      },
    ],
    required: true,
    value: 'Canada',
  },
  // {
  //   key: 'issuanceMetaTemplateIds',
  //   label: 'Jurisdictions',
  //   type: 'custom',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'shortDescription',
  //   label: 'Short Description',
  //   type: 'text-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'plan',
  //   label: 'plan',
  //   type: 'text-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'irr',
  //   label: 'IRR (%)',
  //   type: 'number-input',
  //   step: 0.01,
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'roi',
  //   label: 'ROI (%)',
  //   type: 'number-input',
  //   step: 0.01,
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'walkScore',
  //   label: 'Walk Score',
  //   type: 'number-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'transitScore',
  //   label: 'Transit Score',
  //   type: 'number-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'category',
  //   label: 'Property Type',
  //   type: 'select',
  //   options: [
  //     {
  //       key: 'Core',
  //       name: 'Core',
  //     },
  //     {
  //       key: 'Core Plus',
  //       name: 'Core Plus',
  //     },
  //     {
  //       key: 'Value-Add',
  //       name: 'Value-Add',
  //     },
  //     {
  //       key: 'Opportunistic',
  //       name: 'Opportunistic',
  //     },
  //   ],
  //   required: false,
  //   value: null,
  // },
  {
    key: 'term',
    label: 'Term (Years)',
    type: 'number-input',
    required: false,
    value: null,
  },
  // {
  //   key: 'customPropTypeAndOfferingDate',
  //   label: '',
  //   type: 'custom',
  //   required: false,
  //   value: null,
  //   options: [
  //     {
  //       key: 'exempt',
  //       name: 'exempt',
  //     },
  //     {
  //       key: 'offering_memorandum',
  //       name: 'offering memorandum',
  //     },
  //     {
  //       key: 'crowdfunding',
  //       name: 'crowdfunding',
  //     },
  //   ],
  // },
  {
    key: 'tokensStartBalance',
    label: 'Securities Total',
    type: 'number-input',
    required: false,
    value: null,
  },
  {
    key: 'coinPricePerToken',
    label: 'Cost Per Security',
    type: 'number-input',
    step: 0.01,
    required: false,
    value: null,
  },
  {
    key: 'parcelIdentifier',
    label: 'Parcel Identifier',
    type: 'text-input',
    required: false,
    value: null,
  },
  // {
  //   key: 'securitiesType',
  //   label: 'Type of Security',
  //   type: 'select',
  //   options: [
  //     {
  //       key: 'LPU',
  //       name: 'LPU (LP Units)',
  //     },
  //     {
  //       key: 'CMS',
  //       name: 'CMS (Common Shares)',
  //     },
  //   ],
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'accreditedInvestorLimit',
  //   label: 'Accredited Investor Limit (per Investor)',
  //   type: 'number-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'generalPartnerType',
  //   label: 'General Partner Type',
  //   type: 'select',
  //   options: [
  //     {
  //       key: 'Coming Up',
  //       name: 'Coming Up',
  //     },
  //     {
  //       key: 'Established',
  //       name: 'Established',
  //     },
  //     {
  //       key: 'OG',
  //       name: 'OG',
  //     },
  //   ],
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'estimatedOwnersDays',
  //   label: 'Estimated Owners\' Days',
  //   type: 'number-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'reelUrls',
  //   label: 'Reel URLs',
  //   icon: 'link',
  //   type: 'tag-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'siteArea',
  //   label: 'Site Area',
  //   type: 'text-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'saleable',
  //   label: 'Saleable Area',
  //   type: 'text-input',
  //   required: false,
  //   value: null,
  // },
  // {
  //   key: 'zoning',
  //   label: 'Zoning',
  //   type: 'text-input',
  //   required: false,
  //   value: null,
  // },
]

export {
  baseFields,
}
